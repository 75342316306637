import Vue from 'vue'
import VueRouter from 'vue-router'
import '@/assets/css/global.css'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {path: '/', redirect: '/index'},
  {path: '/index', component: () => import('@/views/login/home')},
  {path: '/caveat', component: () => import('@/views/login/caveat')},
  {path: '/register', component: () => import('@/views/home/register')},
  {path: '/noticeDetail', component: () => import('@/views/home/noticeDetail')},
  {path: '/moreNotices', component: () => import('@/views/home/moreNotices')},
  {path: '/mation', component: () => import('@/views/home/mation')},
  {path: '/temp', component: () => import('@/views/home/temps/temp')},
  {path: '/audit', component: () => import('@/views/home/audit/audit')},
  {path: '/achieve', component: () => import('@/views/home/achieve')},
  {path: '/notice', component: () => import('@/views/home/notice')},
  {path: '/details', component: () => import('@/views/home/details')},
  {path: '/enquiry', component: () => import('@/views/home/enquiry')},
  {path: '/InquiryDetails', component: () => import('@/views/home/InquiryDetails')},
  {path: '/notification', component: () => import('@/views/home/notification')}
]

function getAddress() {
  const protocol = window.location.protocol
  const host = window.location.host
  return window.location.href = protocol + '//' + host + '/main-ui/#/caveat'
  // return window.location.href = 'http://localhost:8888/#/caveat'
}

function IEVersion() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion == 7) {
      return 0;
    } else if (fIEVersion == 8) {
      return 0;
    } else if (fIEVersion == 9) {
      return 0;
    } else if (fIEVersion == 10) {
      return 0;
    } else {
      return 0;
    }
  } else if (isEdge) {
    return 0;
  } else if (isIE11) {
    return 0;
  } else {
    return -1;//不是ie浏览器
  }
}

// IEVersion()

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (IEVersion() !== -1 && to.path !== '/caveat') {
    return next('/caveat')
  } else {
    // 要去的路径
    if (to.path === '/index') return next()
    if (to.path === '/caveat') return next()
    next()
  }
})

export default router
