import axios from 'axios';
import {MessageBox} from 'element-ui'
import {message} from '@/js/resetMessage.js'
import {router} from '@/router'
import {myDecrypt, setAseEncryption} from "@/js/encryption";


const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  // easy-mock服务挂了，暂时不使用了
  baseURL: 'https://www.wolaidi.com/',
  timeout: 15000
})
let requestErrorArry = []
service.interceptors.request.use(
  config => {
    // get 方法参数处理
    let token = sessionStorage.getItem('token')
    let sn = sessionStorage.getItem('sn')
    config.headers.from = 'WEB'
    config.headers.sn = sn
    if (token) {
      config.headers["token"] = token
    }
    if (config.method === 'get') {
      config.params = {
        t: Date.parse(new Date()) / 1000,
        ...config.params
      }
      for (let key in config.params) {
        if (config.params[key] == '' || config.params[key] == null) {
          delete config.params[key]
        }
      }
    }
    // post 请求加密参数
    if (config.method === 'post') {
      config.headers["Content-Type"] = 'application/json'
      //加密
      let data = setAseEncryption(JSON.stringify(config.data))
      let encrypted = {
        SVavTBrYY: data
      }
      config.data = encrypted
    }
    return config
  },
  // error => {
  //     return Promise.reject();
  // }
);

service.interceptors.response.use(
  response => {
    if (response) {
      //解密
      let responseData = myDecrypt(response.data)
      if (response.status === 200) {
        if (responseData.code == 0) {
          return responseData;
        } else {
          message.error({
            message: responseData.msg,
            // type: 'error',
            duration: 3 * 1000
          })
          return responseData;
        }
      } else {
        message.error({
          message: responseData.message,
          duration: 3 * 1000
        })
        Promise.reject();
      }
    }
  },
  error => {
    // 请求401时返回登录页面
    if (error.response.status === 401) {
      requestErrorArry.push(error.response.status)
      if (requestErrorArry.length <= 1)
        MessageBox.confirm('登陆失效，请重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          router.push({path: '/login'})
        })
      return false
    } else {
      message.error({
        message: error.message,
        // type: 'error',
        duration: 3 * 1000
      })
    }
    return Promise.reject();
  }
);

export default service;
